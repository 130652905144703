<template>
    <div>
        <div class="t-page" v-show="isSuccess == false">
            <div class="banner">
                <img src="https://resources.holowits.com.sg/HC/invi-banner0821.jpg" alt="">
                <p style="color: #000000; font-size: 11px;">*请一线为回国客户团组填写申请。</p>
                <p style="color: #000000; font-size: 11px;">*陪同人员应为9月19日和20日实际陪同客户团组在上海参观的同事。</p>
                <p style="color: #000000; font-size: 11px;">*提单人即本需求单填写人。</p>
            </div>
            <div class="title first">
                <!-- {{ international[language].title1 }} -->
                Basic Information
            </div>
            <div class="t-input" :class="{'error': rules.area}">
                <el-select v-model="form.area" filterable placeholder="区域 Region" style="width: 100%;" @focus="activity1 = true" @blur="activity1 = false">
                    <el-option label="亚太 Asia Pacific" value="亚太"></el-option>
                    <el-option label="中东中亚 Middle East" value="中东中亚"></el-option>
                    <el-option label="北非 North Africa" value="北非"></el-option>
                    <el-option label="南非 South Africa" value="南非"></el-option>
                    <el-option label="欧洲 Europe" value="欧洲"></el-option>
                    <el-option label="拉美 Latin America" value="拉美"></el-option>
                </el-select>
                <div :class="['t-label', form.area != ''? 'focus': null, activity1 ? 'focus2': null]">区域 Region</div>
            </div>
            <div class="t-input" :class="{'error': rules.country}">
                <input class="el-input__inner" v-model="form.country"/>
                <div :class="['t-label', form.country != ''? 'focus': null]">国家 Country</div>
            </div>
            <div class="t-input" :class="{'error': rules.companyName}">
                <input class="el-input__inner" v-model="form.companyName"/>
                <div :class="['t-label', form.companyName != ''? 'focus': null]">客户名称 Customer Name</div>
            </div>
            <div class="t-input" :class="{'error': rules.industry}">
                <el-select v-model="form.industry" filterable placeholder="客户行业 Customer Industry" style="width: 100%;" @focus="activity6 = true" @blur="activity6 = false">
                    <el-option label="安平 Safe City" value="安平"></el-option>
                    <el-option label="交通 Transportation" value="交通"></el-option>
                    <el-option label="公共事业 Public Utilities" value="公共事业"></el-option>
                    <el-option label="教育 Education" value="教育"></el-option>
                    <el-option label="金融 Finance" value="金融"></el-option>
                    <el-option label="医疗 Healthcare" value="医疗"></el-option>
                    <el-option label="电力 Electric Power" value="电力"></el-option>
                    <el-option label="能源 Energy" value="能源"></el-option>
                    <el-option label="制造 Manufacturing" value="制造"></el-option>
                    <el-option label="互联网服务提供商 ISP" value="ISP"></el-option>
                    <el-option label="中小企业 SMEs" value="中小企业"></el-option>
                    <el-option label="其他 Other" value="其他"></el-option>
                </el-select>
                <div :class="['t-label', form.industry != ''? 'focus': null]">客户行业 Customer Industry</div>
            </div>
            <div class="t-input" :class="{'error': rules.numberOfPeople}">
                <input class="el-input__inner" v-model="form.numberOfPeople"/>
                <div :class="['t-label', form.numberOfPeople != ''? 'focus': null]">客户人数 Number of Customers</div>
            </div>
            <div class="t-input" :class="{'error': rules.accompanyingPerson}">
                <textarea class="el-input__inner" rows="5" cols="30" style="width: 100%; height: 75px;" v-model="form.accompanyingPerson"></textarea>
                <div :class="['t-label', form.accompanyingPerson != ''? 'focus': null]">陪同人员（姓名，工号）Accompanying Personnel (Name, Employee ID)</div>
            </div>
            <div class="t-input" :class="{'error': rules.submiter}">
                <textarea class="el-input__inner" rows="5" cols="30" style="width: 100%; height: 75px;" v-model="form.submiter"></textarea>
                <div :class="['t-label', form.submiter != ''? 'focus': null]">提单人（姓名，工号）Submitter (Name, Employee ID)</div>
            </div>
            <div class="t-input">
                <input class="el-input__inner" v-model="form.opportunity"/>
                <div :class="['t-label', 'optional', form.opportunity != ''? 'focus': null]">机会点描述 Opportunity Description</div>
            </div>
            <div class="t-input">
                <input class="el-input__inner" v-model="form.communication"/>
                <div :class="['t-label', 'optional', form.communication != ''? 'focus': null]">交流重点 Key Discussion Points</div>
            </div>
            <div class="title first">
                Choose business activities for customers
            </div>
            <div class="t-input" :class="{'error': rules.newProductLaunchTime}">
                <el-select v-model="form.newProductLaunchTime" filterable placeholder="秋季新品发布会" style="width: 100%;" @focus="activity5 = true" @blur="activity5 = false">
                    <el-option label="不参与 Not Participate" value="不参与"></el-option>
                    <el-option label="9月20日：14:00-16:20" value="9月20日：14:00-16:20"></el-option>
                </el-select>
                <div :class="['t-label', form.newProductLaunchTime != ''? 'focus': null, activity5 ? 'focus2': null]">HOLOWITS 2024 秋季新品发布会 HOLOWITS Autumn 2024 New Product Launch</div>
            </div>
            <div class="t-input" :class="{'error': rules.exhibitionTime}">
                <el-select v-model="form.exhibitionTime" filterable placeholder="参观HOLOWITS产品和方案展示活动" style="width: 100%;" @focus="activity2 = true" @blur="activity2 = false">
                    <el-option label="不参与 Not Participate" value="不参与"></el-option>
                    <el-option label="9月19日：09:00-10:00" value="9月19日：09:00-10:00"></el-option>
                    <el-option label="9月19日：10:00-11:00" value="9月19日：10:00-11:00"></el-option>
                    <el-option label="9月19日：11:00-12:00" value="9月19日：11:00-12:00"></el-option>
                    <el-option label="9月19日：12:00-13:00" value="9月19日：12:00-13:00"></el-option>
                    <el-option label="9月19日：13:00-14:00" value="9月19日：13:00-14:00"></el-option>
                    <el-option label="9月19日：14:00-15:00" value="9月19日：14:00-15:00"></el-option>
                    <el-option label="9月19日：15:00-16:00" value="9月19日：15:00-16:00"></el-option>
                    <el-option label="9月19日：16:00-17:00" value="9月19日：16:00-17:00"></el-option>
                    <el-option label="9月19日：17:00-18:00" value="9月19日：17:00-18:00"></el-option>
                    <el-option label="9月20日：09:00-10:00" value="9月20日：09:00-10:00"></el-option>
                    <el-option label="9月20日：10:00-11:00" value="9月20日：10:00-11:00"></el-option>
                    <el-option label="9月20日：11:00-12:00" value="9月20日：11:00-12:00"></el-option>
                    <el-option label="9月20日：12:00-13:00" value="9月20日：12:00-13:00"></el-option>
                    <el-option label="9月20日：13:00-14:00" value="9月20日：13:00-14:00"></el-option>
                    <el-option label="9月20日：14:00-15:00" value="9月20日：14:00-15:00"></el-option>
                    <el-option label="9月20日：15:00-16:00" value="9月20日：15:00-16:00"></el-option>
                    <el-option label="9月20日：16:00-17:00" value="9月20日：16:00-17:00"></el-option>
                    <el-option label="9月20日：17:00-18:00" value="9月20日：17:00-18:00"></el-option>
                </el-select>
                <div :class="['t-label', form.exhibitionTime != ''? 'focus': null, activity2 ? 'focus2': null]">参观HOLOWITS产品和方案展示活动 Exhibition Session</div>
            </div>
            <div class="t-input" :class="{'error': rules.exchangeTime}">
                <el-select v-model="form.exchangeTime" filterable placeholder="预约高层或高级专家同客户交流" style="width: 100%;" @focus="activity3 = true" @blur="activity3 = false">
                    <el-option label="不参与 Not Participate" value="不参与"></el-option>
                    <el-option label="9月19日：09:00-10:00" value="9月19日：09:00-10:00"></el-option>
                    <el-option label="9月19日：10:00-11:00" value="9月19日：10:00-11:00"></el-option>
                    <el-option label="9月19日：11:00-12:00" value="9月19日：11:00-12:00"></el-option>
                    <el-option label="9月19日：12:00-13:00" value="9月19日：12:00-13:00"></el-option>
                    <el-option label="9月19日：13:00-14:00" value="9月19日：13:00-14:00"></el-option>
                    <el-option label="9月19日：14:00-15:00" value="9月19日：14:00-15:00"></el-option>
                    <el-option label="9月19日：15:00-16:00" value="9月19日：15:00-16:00"></el-option>
                    <el-option label="9月19日：16:00-17:00" value="9月19日：16:00-17:00"></el-option>
                    <el-option label="9月19日：17:00-18:00" value="9月19日：17:00-18:00"></el-option>
                    <el-option label="9月20日：09:00-10:00" value="9月20日：09:00-10:00"></el-option>
                    <el-option label="9月20日：10:00-11:00" value="9月20日：10:00-11:00"></el-option>
                    <el-option label="9月20日：11:00-12:00" value="9月20日：11:00-12:00"></el-option>
                    <el-option label="9月20日：12:00-13:00" value="9月20日：12:00-13:00"></el-option>
                    <el-option label="9月20日：13:00-14:00" value="9月20日：13:00-14:00"></el-option>
                    <!-- <el-option label="9月20日：14:00-15:00" value="9月20日：14:00-15:00"></el-option>
                    <el-option label="9月20日：15:00-16:00" value="9月20日：15:00-16:00"></el-option>
                    <el-option label="9月20日：16:00-17:00" value="9月20日：16:00-17:00"></el-option> -->
                    <el-option label="9月21日：09:00-10:00" value="9月21日：09:00-10:00"></el-option>
                    <el-option label="9月21日：10:00-11:00" value="9月21日：10:00-11:00"></el-option>
                    <el-option label="9月21日：11:00-12:00" value="9月21日：11:00-12:00"></el-option>
                </el-select>
                <div :class="['t-label', form.exchangeTime != ''? 'focus': null, activity3 ? 'focus2': null]">预约高层或高级专家同客户交流 Executive Exchange</div>
            </div>
            <div class="t-input" style="display: flex; justify-content: center;">
                <el-button round style="background-color: #c7000b; color: #ffffff; width: 160px;" :loading="isLoading" @click="add">Submit</el-button>
            </div>
        </div>
        <div v-show="isSuccess === true" style="text-align: center;">
            <img src="https://resources.holowits.com.sg/HC/internal082111.jpg" alt="">
        </div>
    </div>
</template>
  
<script>
  import countrys from '@/utils/country.json';
  import {save} from '@/api/otherFormApi';
  export default {
    name: 'ShanghaiInternalIndex',
  data() {
      return {
          isSuccess: false,
          language: 'en',
          activity1: false,
          activity2: false,
          activity3: false,
          activity5: false,
          activity6: false,
          form: {
            area: '',
            country: '',
            companyName: '',
            industry: '',
            numberOfPeople: '',
            accompanyingPerson: '',
            submiter: '',
            opportunity: '',
            communication: '',
            newProductLaunchTime: '',
            exhibitionTime: '',
            exchangeTime: '',
            source: ''
          },
          rules: {
            area: false,
            country: false,
            companyName: false,
            industry: false,
            numberOfPeople: false,
            accompanyingPerson: false,
            submiter: false,
            newProductLaunchTime: false,
            exhibitionTime: false,
            exchangeTime: false,
          },
          countryArr: [],
          isLoading: false,
          hasError: false
      }
  },
  created() {
      this.countryArr = countrys.country.map(item => {
          return {label: item, value: item}
      })
  },
  watch: {
    form: {
        handler(n, v) {
            let checkList = Object.keys(n)
            for (let key of checkList) {
                this.check(key, n[key])
            }
        },
        deep: true
    }
    // 'form.area'(n, o) {
    //     this.check('area', n)
    // },
    // 'form.country'(n, o) {
    //     this.check('country', n)
    // },
    // 'form.companyName'(n, o) {
    //     this.check('companyName', n)
    // },
    // 'form.exhibitionTime'(n, o) {
    //     this.check('exhibitionTime', n)
    // },
    // 'form.exchangeTime'(n, o) {
    //     this.check('exchangeTime', n)
    // },
    // 'form.publicTime'(n, o) {
    //     this.check('publicTime', n)
    // },
  },
  methods: {
    check(key, value) {
        if (key in this.rules) {
            return this.rules[key] = (value ? false : true);
        }
    },
     async add() {
        this.form['source'] = 'shanghai-internal'
        let checkList = Object.keys(this.rules)
        let hasError = false
        for (let key of checkList) {
            if (this.check(key, this.form[key])) {
                hasError = true
            }
        }

        if (hasError) {
            return false;
        }

          this.isLoading = true
          try {
            const {data: {data, isSuccess}} = await save({
                content: JSON.stringify(this.form),
                pageSource: 'shanghai-internal'
            })
            if (isSuccess) {
                this.isSuccess = true
                this.isLoading = false
            } else {
                this.isLoading = false
            }
          } catch(e) {
            this.isLoading = false
          }
          
    }
  }
  }
</script>
  
<style lang="scss" scoped>
  .t-page {
    padding-bottom: 30px;
  }
  .banner {
      width: 100%;
      img {
          width: 100%;
      }
  }
  .error-info {
      color: red;
      font-size: 12px;
  }
  .company-icon {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
  
      .left {
          width: 163px;
          height: 80px;
      }
      .line {
          height: 40px;
          width: 2px;
          background-color: #000000;
      }
      .right {
          width: 105px;
          height: 105px;
      }
  }
  .first {
      margin-top: 20px;
  }
  
  .title {
      font-size: 18px;;
      font-weight: 600;
      margin-bottom: 20px;
  }
  .require {
    &::after {
        content: '*';
        padding-left: 2px;
        color: #c7000b;
    }
  }
  .t-input {
      margin-bottom: 20px;
      position: relative;
  
      .t-label {
          position: absolute;
          font-size: 14px;
          top: 10px;
          left: 16px;
          background-color: #ffffff;
          color: #C0C4CC;
          transition: all 0.4s;
  
          &::after {
              content: '*';
              padding-left: 2px;
              color: #c7000b;
          }
      }
      .optional {
          &::after {
              content: '';
          }
      }
      .focus {
          padding: 0 5px;
          top: -12px;
          font-size: 12px;
      }
      .focus2 {
          padding: 0 5px;
          top: -12px;
          left: 12px;
          font-size: 12px;
      }
      .el-input__inner:focus+.t-label {
          padding: 0 5px;
          top: -12px;
          font-size: 12px;
      }
  }
  .error {
    ::v-deep input {
        border-color: rgb(199, 0, 11) !important;
    }
    ::v-deep textarea {
        border-color: rgb(199, 0, 11) !important;
    }
  }
  @media only screen and (min-width: 992px) {
      .t-page {
          max-width: 838px;
          margin: 20px auto;
      }
  }
  @media only screen and (max-width: 991px) {
      .t-page {
          width: 100%;
          margin: 20px auto;
          padding: 15px;
      }
  }
</style>